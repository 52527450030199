<template>
  <div class="mb-5">
    <header class="svg-logo d-flex align-center justify-center">
      <v-img class="mr-5" max-width="50px" src="../../../public/assets/img/svg/fibonacci-calculator.svg"></v-img>
      <h1 class="text-h5">Fibonacci Calculator</h1>
    </header>
    <p>Privacy policy: The Android app doesn't collect any data.</p>

    <section class="mt-4">
      Credit:
      <Url :dark-mode="$vuetify.theme.isDark" href="https://en.wikipedia.org/wiki/Fibonacci_number"
        >Leonardo Bonacci
      </Url>
    </section>

    <div class="my-4">You can press the up/down arrow keys to increment/decrement each text field’s number.</div>

    <v-form ref="formFibonacci" autocomplete="off">
      <input autocomplete="off" type="hidden" />

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model.trim="number1"
            :rules="rulesNumber1"
            autofocus
            data-number-type="number1"
            inputmode="number"
            label="First number"
            type="text"
            @keypress="preventNonNumber"
            @keydown.up="incrementNumber"
            @keydown.down="decrementNumber"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model.trim="number2"
            :rules="rulesNumber2"
            data-number-type="number2"
            inputmode="number"
            label="Second number"
            type="text"
            @keypress="preventNonNumber"
            @keydown.up="incrementNumber"
            @keydown.down="decrementNumber"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model.trim="numberIterations"
            :rules="rules"
            data-number-type="numberIterations"
            inputmode="number"
            label="Iterations"
            type="text"
            @keypress="preventNonNumber($event, true)"
            @keydown.up="incrementNumber($event, true)"
            @keydown.down="decrementNumber"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <transition name="slide">
      <section v-if="$refs.formFibonacci && $refs.formFibonacci.validate() && getIsNumbers()" class="output">
        <v-checkbox v-model="isFlipSequence" label="Flip sequence"></v-checkbox>
        <section>Sequence in a row:</section>

        <transition-group class="output-sequence-row" name="output-sequence" tag="output">
          <div v-for="{ number, iteration } of sequenceOutput" :key="iteration">
            {{ number }}
          </div>
        </transition-group>

        <section class="mt-4">
          Sequence sum:
          <output>{{ output.sum }}</output>
        </section>

        <section class="mt-4">Sequence in a list:</section>
        <transition-group name="output-sequence" tag="output">
          <div v-for="{ number, iteration } of sequenceOutput" :key="iteration">
            <span class="iteration">#{{ iteration }})</span> (Digits:
            {{ commaSeparated(number.replace(/[,-]/g, "").length) }})
            {{ number }}
          </div>
        </transition-group>
      </section>
    </transition>
  </div>
</template>

<script>
import { calculateFibonacci } from "@/views/products/calculators";
import { mdiArrowDown } from "@mdi/js";
import Url from "@/components/Url";
import { mapGetters } from "vuex";
import BigInt from "big.js";

export default {
  name: "FibonacciCalculator",
  components: { Url },
  data() {
    return {
      number1: "",
      number2: "",
      numberIterations: "",
      output: {},
      isFlipSequence: false,
      rules: [],
      rulesNumber1: [],
      rulesNumber2: [],
      mdiArrowDown
    };
  },
  watch: {
    number1() {
      this.prepareToFibonacci();
    },
    number2() {
      this.prepareToFibonacci();
    },
    numberIterations() {
      this.prepareToFibonacci();
    }
  },
  computed: {
    ...mapGetters(["commaSeparated"]),
    sequenceOutput() {
      if (!this.output.sequence) {
        return [];
      }
      if (this.isFlipSequence) {
        return [...this.output.sequence].reverse();
      }
      return this.output.sequence;
    }
  },
  methods: {
    async prepareToFibonacci() {
      await this.$nextTick();
      if (!this.$refs.formFibonacci.validate()) {
        return;
      }
      this.output = await calculateFibonacci({
        number1: this.number1,
        number2: this.number2,
        numberIterations: this.numberIterations
      });
    },
    getIsNumbers(numToCheck) {
      if (numToCheck === undefined) {
        try {
          BigInt(this.number1 || "e");
          BigInt(this.number2 || "e");
          BigInt(this.numberIterations || "e");
          return true;
        } catch {
          return false;
        }
      }

      const num = numToCheck.toString();

      if (this.number1 === num && this.number2 === num) {
        return true;
      }

      return this.numberIterations === num;
    },
    getInstruction(step) {
      const iteration = BigInt(step.iteration);
      if (!iteration.gt(3)) {
        return step.tutorial;
      }
      if (iteration.eq(4)) {
        return "Again";
      }
      return "";
    },
    preventNonNumber(e, isIteration) {
      const isNumber = !isNaN(e.key);
      const isMinus = e.key === "-";
      if (isIteration && (!isNumber || isMinus)) {
        e.preventDefault();
        return;
      }
      if (!isNumber && !isMinus) {
        e.preventDefault();
      }
    },
    incrementNumber(e) {
      const {
        dataset: { numberType }
      } = e.target.closest("[data-number-type]");
      const number = BigInt(this[numberType] || 0);
      const incrementedNumber = number.add(1);
      this[numberType] = incrementedNumber.toString();
    },
    decrementNumber(e, isIteration) {
      const {
        dataset: { numberType }
      } = e.target.closest("[data-number-type]");
      let number = BigInt(this[numberType] || 0);
      if (number.lt(1) && isIteration) {
        return;
      }
      const incrementedNumber = number.sub(1);
      this[numberType] = incrementedNumber.toString();
    }
  },
  created() {
    this.rules = [
      number => {
        try {
          BigInt(number || "e");
          return true;
        } catch {}

        if (!number) {
          return true;
        }

        return "Must be a number";
      }
    ];

    this.rulesNumber1 = [
      ...this.rules,
      number => {
        if (number && Number(number) === 0 && this.number2 && Number(this.number2) === 0) {
          return "Cannot be 0";
        }

        return true;
      }
    ];

    this.rulesNumber2 = [
      ...this.rules,
      number => {
        if (number && Number(number) === 0 && this.number1 && Number(this.number1) === 0) {
          return "Cannot be 0";
        }

        return true;
      }
    ];
  }
};
</script>

<style>
.chart canvas {
  position: unset !important;
}
</style>

<style scoped>
.output {
  white-space: nowrap;
  overflow-y: hidden;
}

.output-sequence-row {
  display: flex;
}

.output-sequence-row > div:not(:last-of-type) {
  margin-right: 0.95em;
}

/*noinspection CssUnusedSymbol*/
.output-sequence-move {
  transition: transform 0.5s;
}

/*noinspection CssUnusedSymbol*/
.slide-leave,
.slide-enter-to {
  max-height: 100vh;
}

.iteration {
  color: blue;
}

@media (prefers-color-scheme: dark) {
  .iteration {
    color: cyan;
  }
}
</style>
